import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  const title = "Meghna Srivastava"

  const page = {}
  page.title = "Contact"
  page.content = `<div class="contact">
    <h2>Want to say hello?</h2>
    <p>You can reach out by email via <a href="mailto:meghna.s1906@gmail.com">meghna.s1906@gmail.com</a></p>
    <h3>Prefer social media?</h3>
    <p>I&#8217;m pretty active online so you can find me via any of the following accounts:</p>
    <ul>
    <li><a href="https://twitter.com/hellomeghna" target="_blank" rel="noopener noreferrer">Twitter</a></li>
    <li><a href="https://github.com/meghna-saxena" target="_blank" rel="noopener noreferrer">Github</a></li>
    <li><a href="https://www.linkedin.com/in/hellomeghna" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
    </ul>
    </div>`

  return (
    <Layout location={"/contact"} title={title}>
      <SEO title="Contact" />
      <div
        dangerouslySetInnerHTML={{ __html: page.content }}
        className="contact"
      ></div>
    </Layout>
  )
}

export default Contact
